import { useState } from "react";
import "./App.css";
import { ImageGrid } from "./components/ImageGrid/ImageGrid";
import ProductCard from "./components/ProductCard/ProductCard";
import { SearchBar } from "./components/searchBar/SearchBar";
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";

// const baseUrl = "http://localhost:3001";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function truncateDescription(text, maxWords = 15) {
  let words = text.split(/\s+/);
  words = words.flatMap(word => word.split('/'));
  if (words.length <= maxWords) {
    return text;
  }
  const truncatedWords = words.slice(0, maxWords);
  const truncatedText = truncatedWords.join(" ");
  return truncatedText + "...";
}

function formatPrice(number) {
  const numberString = number.toString();
  const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedNumber;
}

function App() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getListings = async (prompt) => {
    try {
      
      const response = await axios.post(`${baseUrl}/api/listings`, { prompt });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const textPrompt = async (prompt, clearText) => {
    setLoading(true)
    setResults([])

    try {
      const { listings } = await getListings(prompt);
      setLoading(false);
      setResults(listings);
      clearText();    
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const getImageUrl = (media) => {
    console.log(media);
    if (media && media.length > 0) {
      return media[0].MediaURL;
    }
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/300px-No-Image-Placeholder.svg.png";
  };

  return (
    <div className="App">
      <div className="search-bar-container">
        <SearchBar onTextPrompt={(prompt, clearText) => { textPrompt(prompt, clearText) }} />
      </div>

      {loading && (
        <div className="spinnerDiv"> <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="50"
        visible={true}
      /> </div>
      )}

      {results.bundle && results.bundle.length > 0 && (
        <div className="imageGrid">
          <ImageGrid>
            {results.bundle.map((value, i) => (
              <ProductCard
                key={i}
                image={getImageUrl(value.Media)}
                name={value.UnparsedAddress}
                description={truncateDescription(value.PublicRemarks)}
                price={`$${formatPrice(value.ListPrice)}`}
                listingId={value.ListingId}
              />
            ))}
          </ImageGrid>
        </div>
      )}
    </div>
  );
}

export default App;
