import React from "react";
import "./ProductCard.css";

const ProductCard = ({ image, name, description, price, listingId }) => {
  return (
    <div
      className="product-card"
      onClick={(e) =>  {window.open(`https://easydigz.com/listing/${listingId}/${name}`, '_blank');}} 
    >
      <img className="product-card__image" alt="" src={image} />
      <div className="product_div">
        <p className="product-card__brand">{name}</p>
        <p className="product-card__description">{description}</p>
        <p className="product-card__price">{price}</p>
      </div>
    </div>
  );
};

export default ProductCard;
