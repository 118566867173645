import { useState } from "react";
import { FaSearch } from "react-icons/fa";

import "./SearchBar.css";

export const SearchBar = ({ onTextPrompt }) => {
  const [input, setInput] = useState("");

  const fetchData = async(value) => {
    await onTextPrompt(value, clearText)
  };

  const clearText = ()=>{
    setInput("")
  }

  const handleChange = (value) => {
    setInput(value);
  };

  return (
    <div className="searchDiv">
      <div className="input-wrapper">
        <FaSearch id="search-icon" />
        <input
          placeholder="Type to search..."
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <button
        className="btn1"
        onClick={async() => {
          await fetchData(input);
        }}
      >
        {" "}
        Search{" "}
      </button>
    </div>
  );
};
